import React, { useState } from 'react';
import '../../Styles/Post.css';

const Post = ({ imageUrl, videoUrl, caption }) => {
  const [likes,setLikes] = useState(0);
  return (
    <div className="post-container">
      <div className="media-container">
        {imageUrl ? (
          <img src={imageUrl} alt="Post media" className="post-media" />
        ) : videoUrl ? (
          <video className="post-media" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}
      </div>
      <div className="caption-container">
        <p>{caption}</p>
      </div>
      <div className="action-buttons">
        <button onClick={()=>setLikes(likes+1)} className="action-btn">❤️ <span style={{fontSize: '12px'}}>{likes}</span></button>
        <button className="action-btn">💬</button>
        <button className="action-btn">🔗</button>
      </div>
    </div>
  );
};

export default Post;
