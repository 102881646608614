import React, { useState, useEffect } from 'react';
import Post from '../Component/Communities/Post';
import '../Styles/Post.css'; // Ensure you have corresponding styles

const Communities = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    try {
      const storedPosts = JSON.parse(localStorage.getItem('userPosts'));
      console.log('Stored posts:', storedPosts); // Debugging line
      if (Array.isArray(storedPosts)) {
        setPosts(storedPosts);
      } else {
        setPosts([]);
      }
    } catch (error) {
      console.error('Failed to parse posts from localStorage', error);
      setPosts([]);
    }
  }, []);

  return (
    <div className="community-feed">
      {posts.length === 0 ? (
        <p>No posts available.</p>
      ) : (
        posts.map((post, index) => (
          <Post
            key={index}
            imageUrl={post.image}
            videoUrl={post.videoUrl || ''}
            caption={post.caption}
          />
        ))
      )}
    </div>
  );
};

export default Communities;
