import React, { useState } from "react";
import "../Styles/Navbar.css"; // Ensure to link the correct path for your CSS file

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="logo">
          Karth
        </a>
        <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
          <li><a href="/#home">Home</a></li>
          <li><a href="/#debates">Debates</a></li>
          <li><a href="/community">Community</a></li>
          <li><a href="/post">Posts</a></li>
          <li><a href="/#about">About</a></li>
          <li><a href="/login" className="login-btn">Login</a></li>
        </ul>
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <span>&#x2715;</span> : <span>&#9776;</span>}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
