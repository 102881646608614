import React, { useState } from 'react';
import '../Styles/UploadContent.css';

const UploadContent = () => {
    const [image, setImage] = useState(null);
    const [caption, setCaption] = useState('');
    const [hashtags, setHashtags] = useState('');

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
    };

    const handleUpload = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const data = {
            image: URL.createObjectURL(image),
            caption,
            hashtags
        };

        // Get existing posts from localStorage or initialize an empty array
        const existingPosts = JSON.parse(localStorage.getItem('userPosts')) || [];

        // Append new post to existing posts
        existingPosts.push(data);

        // Save updated array to localStorage
        localStorage.setItem('userPosts', JSON.stringify(existingPosts));

        console.log('Admin notified for post verification'); // Simulating admin notification
        alert('Your post has been uploaded and is pending verification.');

        // Reset form
        setImage(null);
        setCaption('');
        setHashtags('');
        e.target.reset();
    };

    return (
        <div className="upload-container">
            <h1>Upload Your Content</h1>
            <form onSubmit={handleUpload} className="upload-form">
                <input type="file" accept="image/*" onChange={handleImageChange} required />
                <textarea placeholder="Write a caption..." value={caption} onChange={(e) => setCaption(e.target.value)} required></textarea>
                <input type="text" placeholder="Enter hashtags..." value={hashtags} onChange={(e) => setHashtags(e.target.value)} required />
                <button type="submit">Upload Post</button>
            </form>
        </div>
    );
};

export default UploadContent;
