import React from "react";
import "../Styles/Hero.css";

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1 className="hero-title">
          Welcome to <span>Karth</span>
        </h1>
        <p className="hero-subtitle">Explore Devotional Content, Engage in Debates, and Join the Community</p>
        <a href="/#community" className="hero-btn">
          Join the Community
        </a>
      </div>
    </section>
  );
};

export default Hero;
