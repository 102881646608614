import React, { useState, useEffect } from 'react';
import '../Styles/AdminPage.css';

const AdminPage = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const storedPosts = JSON.parse(localStorage.getItem('userPostList')) || [];
        setPosts(storedPosts);
    }, []);

    const handleApproval = (index, isApproved) => {
        const updatedPosts = posts.filter((_, i) => i !== index);
        localStorage.setItem('userPostList', JSON.stringify(updatedPosts));
        setPosts(updatedPosts);
        alert(isApproved ? 'Post approved.' : 'Post rejected.');
    };

    return (
        <div className="admin-container">
            <h1>Admin Page</h1>
            <div className="post-list">
                {posts.length === 0 ? (
                    <p>No posts to review.</p>
                ) : (
                    posts.map((post, index) => (
                        <div key={index} className="post-item">
                            <img src={post.image} alt="Post" />
                            <p>{post.caption}</p>
                            <p>Hashtags: {post.hashtags}</p>
                            <button onClick={() => handleApproval(index, true)}>Approve</button>
                            <button onClick={() => handleApproval(index, false)}>Reject</button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default AdminPage;
