import React from 'react'

function NotFound() {
  return (
    <div style={{marginTop: '50%'}}>
        <h1>PAGE NOT FOUND</h1>
    </div>
  )
}

export default NotFound