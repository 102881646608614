import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './PageTransition.css';

const PageTransition = ({ children }) => {
  return (
    <TransitionGroup>
      <CSSTransition
        key={window.location.pathname} // Use the current path as the key to trigger transition
        classNames="page"
        timeout={500}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default PageTransition;