import logo from './logo.svg';
import './App.css';
import NavBar from './Component/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Pages/Login';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import PageTransition from './PageTransition';
import Communities from './Pages/Communities';
import UploadContent from './Pages/UploadContent';
import AdminPage from './Pages/Adminpage';

function App() {
  return (
    <div className="App">
    <Router>
    <NavBar/>
    <PageTransition>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/admin' element={<AdminPage/>}/>
      <Route path='/community' element={<Communities/>}/>
      <Route path='/post' element={<UploadContent/>}/>
      <Route path='*' element={<NotFound/>}/>
    </Routes>
    </PageTransition>
    </Router>
    </div>
  );
}

export default App;
